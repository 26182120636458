"use client";

import { Event } from "@/gql/graphql";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import StarIcon from "@mui/icons-material/Star";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import useEvent from "@/hooks/useEvent";
import { Box } from "@mui/material";
import useLang from "@/hooks/useLang";
import Link from "next/link";
import { convertToUri } from "@/utils/stringMethods";
import dynamic from "next/dynamic";
import Image from "next/image";
import { memo, useMemo, useState } from "react";
import { graphql } from "@/gql";
import { useSubscription } from "@apollo/client";

const LikeCounter = dynamic(() => import("./LikeCounter/LikeCounter"));
const ShareButtons = dynamic(() => import("../ShareButtons/ShareButtons"));
const EventDescription = dynamic(
  () => import("../EventDescription/EventDescription")
);

const UPDATE_EVENT = graphql(`
  subscription UpdateEventStatusCard($key: String!, $eventId: Int!) {
    updateEventStatus(key: $key, eventId: $eventId) {
      id
      countTicketAvailable
      orderTicketIsClose
      eventPrices {
        balanceByUser
        commisionCreationByUser
        eventPublicPriceByUser
        currentUserCurrency
        commissionTicketByUser
        netBenefitsByUser
      }
      title
      startedDate
      userIsInEvent
      userCanScanEvent
      isUserEvent
      endedDate
      note
      startedTime
      endedTime
      isPublish
      longitude
      latitude
      isValidate
      isOpenTicket
      isFree
      orderTicketIsClose
      countTicketAvailable
      numberPeopleMax
      isFreeEvent
      descriptions
      isEventCommissionPaid
      countUserInterestEvent
    }
  }
`);

interface Props {
  event: Event;
  showDescription?: boolean;
  anonymousId: string | null;
  isCompagnyPage?: boolean;
  primaryColor?: string;
  compagnySlug?: string;
}
interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme }) => ({
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
  variants: [
    {
      props: ({ expand }) => !expand,
      style: {
        transform: "rotate(0deg)",
      },
    },
    {
      props: ({ expand }) => !!expand,
      style: {
        transform: "rotate(180deg)",
      },
    },
  ],
}));

function CardEvent({
  event,
  showDescription = true,
  anonymousId,
  isCompagnyPage,
  primaryColor,
  compagnySlug,
}: Props) {
  const [expanded, setExpanded] = useState(false);
  const coverImage = event?.coverImage?.url;
  const { getPrice } = useLang();
  const { eventDate } = useEvent();

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const prefix = useMemo(
    () => (compagnySlug ? `/${compagnySlug}` : ""),
    [compagnySlug]
  );

  useSubscription(UPDATE_EVENT, {
    variables: {
      eventId: event?.id || 0,
      key: "UPDATE_EVENT_STATUS",
    },
    onData: ({ client, data }) => {
      const newEvent = data.data?.updateEventStatus;
      client.cache.modify({
        id: client.cache.identify({
          __typename: "Event",
          id: event?.id || 0,
        }),
        fields: {
          countTicketAvailable() {
            return newEvent?.countTicketAvailable;
          },
          orderTicketIsClose() {
            return newEvent?.orderTicketIsClose;
          },
          eventPrices(existing = {}) {
            return newEvent?.eventPrices || existing;
          },
          title(existing = "") {
            return newEvent?.title || existing;
          },
          startedDate(existing) {
            return newEvent?.startedDate || existing;
          },
          userIsInEvent(existing = false) {
            return newEvent?.userIsInEvent || existing;
          },
          userCanScanEvent(existing = false) {
            return newEvent?.userCanScanEvent || existing;
          },
          isUserEvent(existing = false) {
            return newEvent?.isUserEvent || existing;
          },
          endedDate(existing) {
            return newEvent?.endedDate || existing;
          },
          note(existing = 0) {
            return newEvent?.note || existing;
          },
          startedTime(existing) {
            return newEvent?.startedTime || existing;
          },
          endedTime(existing) {
            return newEvent?.endedTime || existing;
          },
          isPublish(existing = false) {
            return newEvent?.isPublish || existing;
          },
          longitude(existing) {
            return newEvent?.longitude || existing;
          },
          latitude(existing) {
            return newEvent?.latitude || existing;
          },
          isValidate(existing = false) {
            return newEvent?.isValidate || existing;
          },
          isOpenTicket(existing = false) {
            return newEvent?.isOpenTicket || existing;
          },
          isFree(existing = false) {
            return newEvent?.isFree || existing;
          },
          numberPeopleMax(existing) {
            return newEvent?.numberPeopleMax || existing;
          },
          isFreeEvent(existing) {
            return newEvent?.isFreeEvent || existing;
          },
          descriptions(existing = "") {
            return newEvent?.descriptions || existing;
          },
          isEventCommissionPaid(existing = false) {
            return newEvent?.isEventCommissionPaid || existing;
          },
          countUserInterestEvent(existing = 0) {
            return newEvent?.countUserInterestEvent || existing;
          },
        },
      });
    },
  });

  return (
    <Card
      variant="elevation"
      className="card-event"
      sx={{
        border: "none",
        position: "relative",
      }}
    >
      {event.categories.length && (
        <Box
          sx={{
            position: "absolute",
            margin: "5px",
            zIndex: 99,
            height: 40,
            width: 40,
            borderRadius: 20,
          }}
        >
          <Image
            src={event.categories[0].coverImage?.url || ""}
            loading="lazy"
            decoding="async"
            alt={"image-category"}
            style={{
              objectFit: "cover", // Change to cover to maintain aspect ratio,
              borderRadius: 20,
            }}
            fill
            sizes="(max-width: 768px) 40px, (max-width: 1200px) 40px,40px"
          />
        </Box>
      )}
      {!isCompagnyPage && (
        <Box
          sx={{
            position: "absolute",
            margin: "5px",
            zIndex: 99,
            top: 0,
            right: 0,
          }}
        >
          <LikeCounter
            event={event}
            count={event?.countUserInterestEvent}
            anonymousId={anonymousId}
          />
        </Box>
      )}
      <Link
        href={`${prefix}/evenement/${convertToUri(event.title)}-ID-${event.id}`}
        style={{
          height: 270,
          display: "block",
          position: "relative",
        }}
      >
        <Image
          src={coverImage || ""}
          fill
          style={{ objectFit: "cover" }}
          sizes="(max-width: 768px) 270px, (max-width: 1200px) 270px,270px"
          className="card-image"
          alt={event.title}
          priority={true}
        />
      </Link>
      <Link
        href={`${prefix}/evenement/${convertToUri(event.title)}-ID-${event.id}`}
      >
        <CardContent
          sx={{ background: isCompagnyPage ? "transparent" : "black" }}
        >
          <Typography
            sx={{
              color: isCompagnyPage ? "black" : "white",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
              fontSize: "16px",
            }}
            variant="body1"
          >
            {event.title}
          </Typography>
          {!isCompagnyPage && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: 4,
                marginBottom: 4,
              }}
            >
              {[...Array(5)].map((_, index) => (
                <StarIcon
                  key={index}
                  style={{
                    color: index < (event.note || 0) ? "yellow" : "whitesmoke",
                    fontSize: "18px",
                  }}
                />
              ))}
            </div>
          )}
          <Typography
            sx={{
              color: isCompagnyPage ? "gray" : "white",
              fontSize: "14px",
            }}
            variant="body2"
          >
            {event?.eventPrices?.eventPublicPriceByUser
              ? `À partir de : ${getPrice({
                  price: event?.eventPrices?.eventPublicPriceByUser,
                  currency: event?.eventPrices?.currentUserCurrency,
                })}`
              : "Gratuit"}
          </Typography>
          <Typography
            style={{
              color: isCompagnyPage ? primaryColor : "yellow",
              marginTop: 4,
              fontSize: "12px",
            }}
            variant="subtitle2"
          >
            {eventDate(event, false)}
          </Typography>
        </CardContent>
      </Link>

      {!isCompagnyPage && (
        <CardActions disableSpacing sx={{ background: "black" }}>
          <ShareButtons
            url={`/evenement/partage/${convertToUri(event.title)}-ID-${
              event.id
            }`}
          />
          {event.descriptions && showDescription && (
            <ExpandMore
              expand={expanded}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
            >
              <ExpandMoreIcon style={{ color: "white" }} />
            </ExpandMore>
          )}
        </CardActions>
      )}
      {!isCompagnyPage && (
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent sx={{ background: "black", fontSize: "12px" }}>
            <EventDescription event={event as Event} length={length} />
          </CardContent>
        </Collapse>
      )}
    </Card>
  );
}

export default memo(CardEvent);
