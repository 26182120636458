import { memo, useMemo } from "react";
import {
  Box,
  Grid,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Card,
  CardContent,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import PeopleIcon from "@mui/icons-material/People";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { Download } from "@mui/icons-material";
import Slider from "react-slick";
import dynamic from "next/dynamic";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Constants
const SLIDER_SPEED = 500;
const QR_IMAGE_SIZE = 200;
const MOBILE_BREAKPOINT = "lg";

// Dynamic imports
const DownloadButton = dynamic(
  () => import("@/components/Commons/DownloadButton/DownloadButton"),
  { ssr: false }
);

// Styles
const styles = {
  wrapper: {
    backgroundColor: "#fff",
    color: "black",
    p: 2,
    borderRadius: 2,
  },
  promoImage: {
    width: "100%",
    height: "auto",
    borderRadius: "15px",
  },
  qrCode: {
    width: `${QR_IMAGE_SIZE}px`,
    height: `${QR_IMAGE_SIZE}px`,
  },
  mobileCard: {
    backgroundColor: "#f5f5f5",
    margin: 2,
  },
  mobileIcon: {
    display: "flex",
    justifyContent: "center",
  },
  downloadSection: {
    textAlign: "center",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
  },
} as const;

// Content data
const promoItems = [
  {
    icon: <Download sx={{ color: "black" }} />,
    text: "Télécharge notre appli pour suivre tous les événements, profiter des offres exclusives et gérer tes inscriptions facilement avec un abonnement ou à la carte !",
  },
  {
    icon: <PeopleIcon sx={{ color: "black" }} />,
    text: "Utilise tes billets, partage-les avec tes amis, et reçois des notifications en cas de changement de programme.",
  },
  {
    icon: <FavoriteIcon sx={{ color: "black" }} />,
    text: "Grâce à la géolocalisation, trouve rapidement ce qui se passe autour de toi et découvre des événements qui correspondent à tes intérêts.",
  },
] as const;

// Components
const PromoImage = memo(({ className }: { className?: string }) => (
  <Box
    component="img"
    src="/fans-partying.webp"
    className={className}
    alt="Promo Image"
    sx={styles.promoImage}
    loading="lazy"
  />
));

const MobilePromoCard = memo(
  ({ icon, text }: { icon: React.ReactNode; text: string }) => (
    <Card sx={styles.mobileCard}>
      <CardContent>
        <ListItemIcon sx={styles.mobileIcon}>{icon}</ListItemIcon>
        <ListItemText primary={text} />
      </CardContent>
    </Card>
  )
);

const PromoContent = memo(({ isMobile }: { isMobile: boolean }) => {
  const sliderSettings = useMemo(
    () => ({
      dots: true,
      infinite: true,
      speed: SLIDER_SPEED,
      slidesToShow: 1,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 600,
          settings: {
            arrows: false,
          },
        },
      ],
    }),
    []
  );

  return (
    <List>
      <ListItem>
        <Typography
          variant={isMobile ? "h6" : "h4"}
          fontWeight="bold"
          gutterBottom
          align="center"
        >
          Maximise tes sorties avec Le Hub
        </Typography>
      </ListItem>
      {isMobile ? (
        <Slider {...sliderSettings} className="slider-promo">
          {promoItems.map((item, index) => (
            <div key={index}>
              <MobilePromoCard {...item} />
            </div>
          ))}
        </Slider>
      ) : (
        <List>
          {promoItems.map((item, index) => (
            <ListItem key={index}>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItem>
          ))}
        </List>
      )}
    </List>
  );
});

const QRSection = memo(() => {
  const isIOS = useMemo(() => {
    if (typeof window === "undefined") return false;
    return /iPhone|iPad|iPod/i.test(window.navigator.userAgent);
  }, []);

  return (
    <Box sx={styles.downloadSection}>
      <Box
        component="img"
        src={isIOS ? "/ios-hub.webp" : "/android-hb.webp"}
        alt="QR Code"
        sx={styles.qrCode}
        loading="lazy"
      />
      <Box mt={2}>
        <DownloadButton
          backgroundColor="black"
          color="white"
          textColor="white"
        />
      </Box>
    </Box>
  );
});

// Main component
const AppPromo = memo(() => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(MOBILE_BREAKPOINT));

  return (
    <Box sx={styles.wrapper}>
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        spacing={4}
      >
        {!isMobile && (
          <Grid item xs={12} sm={12} md={12} lg={3}>
            <PromoImage className="image-promo" />
          </Grid>
        )}

        <Grid item xs={12} sm={12} md={12} lg={6}>
          <PromoContent isMobile={isMobile} />
        </Grid>

        {isMobile && (
          <Grid item xs={12} sm={12} md={12} lg={3}>
            <PromoImage className="image-promo" />
          </Grid>
        )}

        <Grid item xs={12} sm={12} md={12} lg={3}>
          <QRSection />
        </Grid>
      </Grid>
    </Box>
  );
});

export default AppPromo;
