import { FC, memo, useState, useCallback } from "react";
import { Button, Box } from "@mui/material";
import { CategoryEvents } from "@/gql/graphql";
import CancelIcon from "@mui/icons-material/Cancel";
import Image from "next/image";
import Slider from "react-slick";

// Constants
const SLIDER_SPEED = 500;
const DEFAULT_SLIDES = 10;

// Types
interface CategoryButtonProps extends CategoryEvents {
  selected: boolean;
  onClick: (id: number) => void;
  handleClear: () => void;
}

interface Props {
  getSelectedCategory: (id: number | null) => void;
  categories?: CategoryEvents[];
}

const buttonStyles = {
  base: {
    backgroundColor: "rgb(255, 255, 255)",
    color: "#ffffff",
    width: "90px",
    height: "90px",
    py: 1,
    borderRadius: "12px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textTransform: "none",
    cursor: "pointer",
    mx: 1,
    transition: "transform 0.3s",
    "&:hover": {
      transform: "scale(1.05)",
    },
  },
  cancelIcon: {
    position: "absolute",
    top: 0,
    right: 0,
    "& svg": {
      background: "black",
      color: "white",
      borderRadius: "50%",
    },
  },
  imageContainer: {
    width: "44px",
    height: "44px",
    position: "relative",
  },
  categoryName: {
    mt: 1,
    fontSize: "12px",
    textTransform: "capitalize",
    color: "black",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    paddingX: "5px",
    maxWidth: "80px",
    textAlign: "center",
  },
} as const;

// Slider settings
const sliderSettings = {
  dots: false,
  infinite: true,
  speed: SLIDER_SPEED,
  slidesToShow: DEFAULT_SLIDES,
  slidesToScroll: 1,
  swipe: true,
  swipeToSlide: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: { slidesToShow: 8 },
    },
    {
      breakpoint: 820,
      settings: { slidesToShow: 7 },
    },
    {
      breakpoint: 835,
      settings: { slidesToShow: 6 },
    },
    {
      breakpoint: 618,
      settings: { slidesToShow: 5 },
    },
    {
      breakpoint: 480,
      settings: { slidesToShow: 3 },
    },
    {
      breakpoint: 320,
      settings: { slidesToShow: 2 },
    },
  ],
};

// Optimized CategoryButton Component
const CategoryButton: FC<CategoryButtonProps> = memo(
  ({ selected, onClick, coverImage, name, id, handleClear }) => {
    const handleClearClick = useCallback(
      (e: React.MouseEvent) => {
        e.stopPropagation();
        handleClear();
      },
      [handleClear]
    );

    const handleButtonClick = useCallback(() => {
      if (selected) {
        handleClear();
      } else {
        onClick(id);
      }
    }, [onClick, id, selected]);

    return (
      <Button
        onClick={handleButtonClick}
        variant="contained"
        sx={buttonStyles.base}
        aria-label={`Select category ${name}`}
      >
        {selected && (
          <Box
            component="span"
            onClick={handleClearClick}
            sx={buttonStyles.cancelIcon}
          >
            <CancelIcon />
          </Box>
        )}
        <Box component="div" sx={buttonStyles.imageContainer}>
          <Image
            src={coverImage?.url || ""}
            fill
            loading="lazy"
            decoding="async"
            style={{ objectFit: "cover", borderRadius: "50%" }}
            sizes="44px"
            alt={name}
            priority={false}
          />
        </Box>
        <Box component="span" sx={buttonStyles.categoryName}>
          {name}
        </Box>
      </Button>
    );
  }
);

// Main CategoryMenu Component
const CategoryHomeMenu: FC<Props> = memo(({ getSelectedCategory, categories }) => {
  const [selectedCategory, setSelectedCategory] = useState<number | null>(null);
  const handleCategorySelect = useCallback(
    (id: number) => {
      setSelectedCategory(id);
      getSelectedCategory(id);
    },
    [getSelectedCategory]
  );
  const handleCategoryClear = useCallback(() => {
    setSelectedCategory(null);
    getSelectedCategory(null);
  }, [getSelectedCategory]);
  return (
    <Box sx={{ p: 1, borderRadius: 2 }}>
      <Slider {...sliderSettings}>
        {categories?.map((category) => (
          <Box key={category.id}>
            <CategoryButton
              {...(category as CategoryEvents)}
              selected={selectedCategory === category.id}
              onClick={handleCategorySelect}
              handleClear={handleCategoryClear}
            />
          </Box>
        ))}
      </Slider>
    </Box>
  );
});

export default CategoryHomeMenu;
