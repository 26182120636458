import { Event } from "@/gql/graphql";
import { Grid } from "@mui/material";
import { FC, Fragment, memo, useCallback, useMemo } from "react";
import CardEvent from "@/components/Commons/CardEvent/CardEvent";
import AppPromo from "../AppPromo/AppPromo";

// Constants
const PROMO_THRESHOLD_1 = 4;
const PROMO_THRESHOLD_2 = 7;
const PROMO_INDEX = 7;

interface EventGridProps {
  events: Event[];
  anonymousId: string | null;
  isUserEvent?: boolean;
}

// Animation variants

const EventGridHome: FC<EventGridProps> = memo(
  ({ events, anonymousId, isUserEvent = false }) => {
    // Memoized calculations
    const shouldShowPromo = useMemo(
      () =>
        !isUserEvent &&
        (events.length < PROMO_THRESHOLD_1 ||
          events.length < PROMO_THRESHOLD_2),
      [isUserEvent, events.length]
    );
    // Memoized grid item renderer
    const renderEventItem = useCallback(
      (event: Event, index: number) => (
        <Fragment key={event.id + event.title}>
          <Grid item sm={6} xs={12} md={6} lg={3}>
            <CardEvent event={event} anonymousId={anonymousId} />
          </Grid>
          {index === PROMO_INDEX && !isUserEvent && (
            <Grid item sm={12} my={4}>
              <AppPromo />
            </Grid>
          )}
        </Fragment>
      ),
      [anonymousId, isUserEvent]
    );
    return (
      <Grid container spacing={3}>
        {events.map(renderEventItem)}
        {shouldShowPromo && (
          <Grid item sm={12} my={4}>
            <AppPromo />
          </Grid>
        )}
      </Grid>
    );
  }
);

export default EventGridHome;
