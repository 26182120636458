"use client";

import { useCallback, useMemo, useState, useEffect } from "react";
import {
  Box,
  CircularProgress,
  Container,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { graphql } from "@/gql";
import { useQuery } from "@apollo/client";
import { v4 as uuidv4 } from "uuid";
import { CategoryEvents, Event } from "@/gql/graphql";
import useAnonymousId from "@/hooks/useAnonymousId";
import { useSelectCountry } from "@/context/CountryContext";
import "./HomePage.css";
import InfiniteScroll from "react-infinite-scroll-component";
import AutocompleteSearchField from "./AutocompleteSearchField/AutocompleteSearchField";
import CategoryHomeMenu from "./CategoryHomeMenu/CategoryHomeMenu";
import EventGridHome from "./EventGridHome/EventGridHome";
import DotsLoading from "../Commons/DotsLoading/DotsLoading";

// Constants moved outside the component
const QUERY_SIZE = 12;
const DEFAULT_ANONYMOUS_ID = "web-default-user";

// GraphQL query moved to a separate file
const EVENTS = graphql(`
  query GetEventExplored(
    $take: Int!
    $skip: Int!
    $name: String
    $categoryIds: [Int!]
    $anonymousId: String
    $countryId: Int
  ) {
    getEventExplored(
      take: $take
      skip: $skip
      name: $name
      categoryIds: $categoryIds
      countryId: $countryId
    ) {
      count
      events {
        id
        title
        startedDate
        endedDate
        startedTime
        note
        endedTime
        descriptions
        countUserInterestEvent
        currentUserIsInterest(anonymousId: $anonymousId)
        eventPrices {
          currentUserCurrency
          eventPublicPriceByUser
        }
        countryId
        coverImage {
          id
          url
        }
        categories {
          id
          coverImage {
            id
            url
          }
        }
      }
    }
  }
`);

interface Props {
  events?: Event[];
  categories?: CategoryEvents[];
}

const Home = ({ events = [], categories }: Props) => {
  const { anonymousId, isClient } = useAnonymousId();
  const [selectedCategory, setSelectedCategory] = useState<number | null>(null);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const [loadingMore, setLoadingMore] = useState(false);
  const { countryId } = useSelectCountry();

  const queryVariables = useMemo(
    () => ({
      skip: 0,
      countryId,
      take: QUERY_SIZE,
      anonymousId: anonymousId || DEFAULT_ANONYMOUS_ID,
      ...(selectedCategory && { categoryIds: [selectedCategory] }),
    }),
    [anonymousId, selectedCategory, countryId]
  );

  const { data, loading, fetchMore, refetch } = useQuery(EVENTS, {
    variables: queryVariables,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
    returnPartialData: true,
    ssr: false,
  });

  const eventDatas = useMemo(() => {
    if (data?.getEventExplored) {
      return data?.getEventExplored.events;
    }
    return events;
  }, [events, countryId, selectedCategory, data]);

  const hasMore = useMemo(
    () => (eventDatas?.length ?? 0) < (data?.getEventExplored.count ?? 0),
    [eventDatas?.length, data?.getEventExplored.count]
  );

  const handleFetchMore = useCallback(() => {
    if (loading || !hasMore || loadingMore) return;
    setLoadingMore(true);
    const currentLength = eventDatas.length || 0;
    fetchMore({
      variables: {
        ...queryVariables,
        skip: currentLength,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return {
          getEventExplored: {
            ...fetchMoreResult.getEventExplored,
            events: [
              ...prev.getEventExplored.events,
              ...fetchMoreResult.getEventExplored.events,
            ],
          },
        };
      },
    }).finally(() => setLoadingMore(false));
  }, [eventDatas, loading, fetchMore, hasMore, queryVariables]);

  useEffect(() => {
    if (typeof window === "undefined") return;
    const storedAnonymousId = localStorage.getItem("anonymousId") || uuidv4();
    localStorage.setItem("anonymousId", storedAnonymousId);
  }, []);

  if (!isClient) return <DotsLoading />;
  return (
    <Box sx={{ flex: 1, bgcolor: "black", color: "white", p: 2 }}>
      <Container maxWidth="lg">
        <Box
          component="header"
          sx={{
            mb: 2,
            position: "sticky",
            top: "5.85vh",
            zIndex: 999,
            background: "rgba(0, 0, 0, 0.9)",
            paddingTop: isDesktop ? "5px" : "20px",
            paddingBottom: "10px",
          }}
        >
          <AutocompleteSearchField sx={{ marginBottom: 2 }} />
          <CategoryHomeMenu
            getSelectedCategory={setSelectedCategory}
            categories={categories || []}
          />
        </Box>
        <Box component="main" sx={{ flex: 1, my: 5 }}>
          <InfiniteScroll
            dataLength={eventDatas?.length || 0}
            next={handleFetchMore}
            hasMore={Boolean(hasMore)}
            loader={false}
            refreshFunction={refetch}
            pullDownToRefresh
            pullDownToRefreshThreshold={50}
            scrollThreshold={0.5}
          >
            <EventGridHome
              events={(eventDatas as Event[]) || []}
              anonymousId={anonymousId}
            />
          </InfiniteScroll>
        </Box>
        {loadingMore && (
          <Box
            justifyContent={"center"}
            alignItems={"center"}
            display={"flex"}
            my={4}
          >
            <CircularProgress
              size={35}
              style={{ color: "white", textAlign: "center" }}
            />
          </Box>
        )}
      </Container>
    </Box>
  );
};

export default Home;
